<template>
  <Bartender>
    <div class="bg" />
    <BartenderContent class="site">
      <div class="site__content">
        <h1 class="sr-only">
          Bartender.js demo
        </h1>
        <div class="block">
          <img
            class="logo"
            src="/bartender-logo.webp"
            width="1040"
            height="303"
            draggable="false"
            alt=""
          >
        </div>
        <div class="block">
          <p class="summary">
            Bartender is a library for creating accessible off-canvas bars. Any number of bars is supported, and they can be located on any side of the viewport.
          </p>
        </div>
        <div class="block">
          <nav class="externalNav">
            <ul class="externalNav__list">
              <li class="externalNav__item">
                <a
                  class="externalNav__link"
                  href="https://www.npmjs.com/package/@fokke-/bartender.js"
                  rel="noopener"
                >
                  <i-mdi-npm class="externalNav__icon" />
                  <span class="sr-only">See package in </span>NPM
                </a>
              </li>
              <li class="externalNav__item">
                <a
                  class="externalNav__link"
                  href="https://github.com/Fokke-/bartender.js"
                  rel="noopener"
                >
                  <i-mdi-github class="externalNav__icon" />
                  <span class="sr-only">See </span>GitHub<span class="sr-only"> repository</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="block">
          <div class="columnContainer">
            <div class="column">
              <div class="block">
                <div class="box">
                  <h2>Create a new bar</h2>
                  <BarConfig
                    v-model:name="newBarName"
                    v-model:options="newBarOptions"
                    @submit="barFormSubmit"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="block">
                <div class="box">
                  <h2>Current bars</h2>
                  <div class="buttons">
                    <button
                      v-for="item of bars"
                      :key="`bar-${item.name}`"
                      type="button"
                      :class="[
                        'button',
                        `button--${item.name}`
                      ]"
                      v-bartender-toggle="item.name"
                    >
                      <span class="sr-only">Toggle</span> {{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BartenderContent>
    <BartenderBar
      v-for="bar of bars"
      :key="bar.name"
      :name="bar.name"
      :position="bar.options.position"
      :mode="bar.options.mode"
      :overlay="bar.options.overlay"
      :permanent="bar.options.permanent"
      :scroll-top="bar.options.scrollTop"
      :focus-trap="bar.options.focusTrap"
    >
      <div class="block">
        <h2>Bar '{{ bar.name }}'</h2>
        <BarConfig
          v-model:name="bar.name"
          v-model:options="bar.options"
          edit-mode
        />
      </div>
      <div class="block">
        <h2>Switch to another bar?</h2>
        <div class="buttons">
          <template
            v-for="item of bars"
            :key="item.name"
          >
            <button
              v-if="item.name !== bar.name"
              type="button"
              v-bartender-toggle="item.name"
            >
              <span class="sr-only">Toggle</span> {{ item.name }}
            </button>
          </template>
        </div>
      </div>
    </BartenderBar>
  </Bartender>
</template>

<script setup lang="ts">

import type { Bar } from './types.d'
import type { Ref } from 'vue'

import type {
  BartenderBarMode,
  BartenderBarPosition
} from '@fokke-/bartender.js'

import {
  Bartender,
  BartenderContent,
  BartenderBar
} from '@fokke-/vue-bartender.js'
import BarConfig from './components/BarConfig.vue'
import {
  onMounted,
  ref,
  nextTick
} from 'vue'

const bars: Ref<Bar[]> = ref([])
const newBarName = ref('')
const newBarOptionsDefaults = {
  position: 'left' as BartenderBarPosition,
  mode: 'float' as BartenderBarMode,
  overlay: true,
  permanent: false,
  scrollTop: true,
  focusTrap: true,
}
const newBarOptions = ref({ ...newBarOptionsDefaults })

const createBar = (name: string, options = {}): Promise<void> => {
  return new Promise((resolve) => {
    if (!name) throw new Error('Bar name is required.')
    if (bars.value.some(item => item.name == name)) throw new Error(`Bar with name ${name} already exists.`)

    bars.value.push({
      name,
      options,
    })

    resolve()
  })
}

const barFormSubmit = async () => {
  try {
    await createBar(newBarName.value, { ...newBarOptions.value })
    await nextTick()

    const toggleButton = document.querySelector(`.button--${newBarName.value}`) as HTMLElement
    if (toggleButton) {
      toggleButton.focus()
    }

    newBarName.value = ''
    newBarOptions.value = { ...newBarOptionsDefaults }
  } catch (error) {
    console.error(error)
  }
}

onMounted(async () => {
  createBar('First', {
    ...newBarOptionsDefaults,
    mode: 'push',
  })
  createBar('Second', {
    ...newBarOptionsDefaults,
    position: 'right',
  })
})

</script>

<style lang="scss">

@import 'modern-normalize/modern-normalize.css';
@import '@fokke-/bartender.js/dist/bartender.scss';
@import './assets/utils';
@import './assets/base';
@import './assets/form';

body {
  line-height: 1.5;
  background: rgb(227, 106, 50);
}

.bartender {
  &__bar {
    padding: clamp(1rem, 5vw, 2rem);
  }
}

.site {
  padding: 10vw;
  background: linear-gradient(
    135deg,
    rgb(227, 106, 50) 0%,
    rgb(172, 82, 40) 100%
  );

  &__content {
    max-width: 800px;

    margin: auto;
  }
}

.block {
  margin: 3rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.columnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -1.5rem;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.column {
  padding: 1.5rem;

  @media (min-width: 800px) {
    flex: 0 0 50%;
  }
}

.box {
  padding: 1.5rem;
  background: #fdf9f0;
  box-shadow: 0 .2em .1em .1em rgba(0, 0, 0, .2);
  border-radius: 6px;
}

.logo {
  display: block;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 2rem auto;
  transform: rotate(-5deg);
}

.externalNav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 2rem 0;

  &__list {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0 .5em;
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    padding: .5em 1em;
    color: #fff;

    &:hover,
    &:focus {
      text-decoration-line: underline;
      text-underline-offset: .3em;
    }
  }

  &__icon {
    font-size: 2em;
    margin-right: .2em;
  }

}

</style>
