import { createApp } from 'vue'
import App from './App.vue'

import { createBartender } from '@fokke-/vue-bartender.js'

const app = createApp(App)
const bartender = createBartender()

app
  .use(bartender)
  .mount('#app')
